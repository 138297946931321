body {
  background: #eee;
}

.white-bg {
  background-color: #ffffff;
}

.page-heading {
  border-top: 0;
  padding: 0 10px 20px 10px;
}

.forum-post-container .media {
  margin: 10px 10px 10px 10px;
  padding: 20px 10px 20px 10px;
  border-bottom: 1px solid #f1f1f1;
}

.forum-avatar {
  float: left;
  margin-right: 20px;
  text-align: center;
  width: 110px;
}

.forum-avatar .img-circle {
  height: 48px;
  width: 48px;
}

.author-info {
  color: #676a6c;
  font-size: 11px;
  margin-top: 5px;
  text-align: center;
}

.forum-post-info {
  padding: 9px 12px 6px 12px;
  background: #f9f9f9;
  border: 1px solid #f1f1f1;
}

.media-body > .media {
  background: #f9f9f9;
  border-radius: 3px;
  border: 1px solid #f1f1f1;
}

.forum-post-container .media-body .photos {
  margin: 10px 0;
}

.forum-photo {
  max-width: 140px;
  border-radius: 3px;
}

.media-body > .media .forum-avatar {
  width: 70px;
  margin-right: 10px;
}

.media-body > .media .forum-avatar .img-circle {
  height: 38px;
  width: 38px;
}

.mid-icon {
  font-size: 66px;
}

.forum-item {
  margin: 5px 0;
  padding: 10px 0 20px;
  border-bottom: 1px solid #f1f1f1;
}

.views-number {
  font-size: 24px;
  line-height: 18px;
  font-weight: 400;
}

.forum-container,
.forum-post-container {
  padding: 30px !important;
}

.forum-item small {
  color: #999;
}

.forum-item .forum-sub-title {
  color: #999;
}

.forum-title {
  margin: 15px 0 15px 0;
}

.forum-info {
  text-align: center;
}

.forum-desc {
  color: #999;
}

.forum-day {
  color: #0b8ba1;
  font-weight: bold;
}

.forum-icon {
  float: left;
  width: 30px;
  margin-right: 20px;
  text-align: center;
}

.forum-item-title {
  color: #1c50dd;
  display: block;
  font-size: 18px;
  font-weight: 600;
}

.forum-item-title:hover {
  color: #1ab394;
}

.forum-icon .fa {
  font-size: 30px;
  margin-top: 8px;
  color: #9b9b9b;
}

.forum-item.active .fa {
  color: #1ab394;
}

.forum-item.active a.forum-item-title {
  color: #1ab394;
}

@media (max-width: 992px) {
  .forum-info {
    margin: 15px 0 10px 0;
    /* Comment this is you want to show forum info in small devices */
    display: none;
  }

  .forum-desc {
    float: none !important;
  }
}

.ibox {
  clear: both;
  margin-bottom: 25px;
  margin-top: 0;
  padding: 0;
}

.ibox.collapsed .ibox-content {
  display: none;
}

.ibox.collapsed .fa.fa-chevron-up:before {
  content: "\f078";
}

.ibox.collapsed .fa.fa-chevron-down:before {
  content: "\f077";
}

.ibox:after,
.ibox:before {
  display: table;
}

.ibox-title {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  background-color: #ffffff;
  border-color: #e7eaec;
  border-image: none;
  border-style: solid solid none;
  border-width: 3px 0 0;
  color: inherit;
  margin-bottom: 0;
  padding: 14px 15px 7px;
  min-height: 48px;
}

.ibox-content {
  background-color: #ffffff;
  color: inherit;
  padding: 15px 20px 20px 20px;
  border-color: #e7eaec;
  border-image: none;
  border-style: solid solid none;
  border-width: 1px 0;
}

.ibox-footer {
  color: inherit;
  border-top: 1px solid #e7eaec;
  font-size: 90%;
  background: #ffffff;
  padding: 10px 15px;
}

.message-input {
  height: 90px !important;
}

.form-control,
.single-line {
  background-color: #ffffff;
  /* background-image: none; */
  border: 1px solid #e5e6e7;
  border-radius: 1px;
  color: inherit;
  display: block;
  padding: 6px 12px;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  width: 100%;
  font-size: 14px;
}

.text-navy {
  color: #1ab394;
}

.mid-icon {
  font-size: 66px !important;
}

.m-b-sm {
  margin-bottom: 10px;
}
.img-size {
  height: 80px !important;
}

.logo-icon {
  float: left;
  width: 20%;
  margin-right: 20px;
  text-align: center;
}
.logo-icon-company-registration {
  float: left;
  width: 15%;
  margin-right: 20px;
  text-align: center;
}

.widget-49-title-wrapper .widget-49-date-Monday {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #edf1fc;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  float: left;
}

.widget-49-title-wrapper .widget-49-date-Monday .widget-49-date-day {
  color: #4e73e5;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
}

.widget-49-title-wrapper .widget-49-date-Monday .widget-49-date-month {
  color: #4e73e5;
  line-height: 1;
  font-size: 1rem;
  text-transform: uppercase;
}

.widget-49-title-wrapper .widget-49-date-Tuesday {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #dededf;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  float: left;
}

.widget-49-title-wrapper .widget-49-date-Tuesday .widget-49-date-day {
  color: #666769;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
}

.widget-49-title-wrapper .widget-49-date-Tuesday .widget-49-date-month {
  color: #666769;
  line-height: 1;
  font-size: 1rem;
  text-transform: uppercase;
}

.widget-49-title-wrapper .widget-49-date-Sunday {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #e8faf8;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  float: left;
}

.widget-49-title-wrapper .widget-49-date-Sunday .widget-49-date-day {
  color: #17d1bd;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
}

.widget-49-title-wrapper .widget-49-date-Sunday .widget-49-date-month {
  color: #17d1bd;
  line-height: 1;
  font-size: 1rem;
  text-transform: uppercase;
}

.widget-49-title-wrapper .widget-49-date-Wednesday {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #ebf7ff;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  float: left;
}

.widget-49-title-wrapper .widget-49-date-Wednesday .widget-49-date-day {
  color: #36afff;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
}

.widget-49-title-wrapper .widget-49-date-Wednesday .widget-49-date-month {
  color: #36afff;
  line-height: 1;
  font-size: 1rem;
  text-transform: uppercase;
}

.widget-49-title-wrapper .widget-49-date-Thursday {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: floralwhite;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  float: left;
}

.widget-49-title-wrapper .widget-49-date-Thursday .widget-49-date-day {
  color: #ffc868;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
}

.widget-49-title-wrapper .widget-49-date-Thursday .widget-49-date-month {
  color: #ffc868;
  line-height: 1;
  font-size: 1rem;
  text-transform: uppercase;
}

.widget-49-title-wrapper .widget-49-date-Saturday {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #feeeef;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  float: left;
}

.widget-49-title-wrapper .widget-49-date-Saturday .widget-49-date-day {
  color: #f56d76;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
}

.widget-49-title-wrapper .widget-49-date-Saturday .widget-49-date-month {
  color: #f56d76;
  line-height: 1;
  font-size: 1rem;
  text-transform: uppercase;
}

.widget-49-title-wrapper .widget-49-date-Friday {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #feeeef;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  float: left;
}

.widget-49-title-wrapper .widget-49-date-Friday .widget-49-date-day {
  color: #f56d76;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
}

.widget-49-title-wrapper .widget-49-date-Friday .widget-49-date-month {
  color: #f56d76;
  line-height: 1;
  font-size: 1rem;
  text-transform: uppercase;
}

.error {
  color: red;
}

.error input {
  border-color: red;
}
.error select {
  border-color: red;
}
.error input:focus {
  border-color: red;
}
.error input:focus {
  border-color: red;
}

.error textarea {
  border-color: red;
}
.error textarea:focus {
  border-color: red;
}

.input-wrap .help > input[type="text"]:focus {
  background: orange;
}

.error .errCls > div {
  background: rgb(255, 0, 0);
  margin-top: -5px;
  border-radius: 0 0 5px 5px !important;
  padding: 4px;
  font-size: small;
}
.marginLeft {
  margin-left: 50px;
}

.select {
  width: 100%;
  min-width: 15ch;
  max-width: 30ch;
  border: 0px solid var(--select-border);
  border-radius: 0.25em;
  padding: 0.25em 0.5em;
  font-size: 1rem;
  cursor: pointer;
  line-height: 1.1;
  background-color: #fff;
  background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
}
option {
  white-space: normal;

  outline-color: var(--select-focus);
}

.white-autocomplete .MuiInputBase-root {
  color: white;
}
.white-autocomplete .MuiInputBase-input {
  color: white;
}
.white-autocomplete .MuiAutocomplete-popupIndicator {
  color: white;
}
.white-autocomplete .MuiAutocomplete-option {
  color: white;
}
.white-autocomplete .MuiAutocomplete-option[aria-selected="true"] {
  background-color: #3184fd;
}

.white-autocomplete .MuiAutocomplete-listbox {
  background-color: #333;
}
.white-autocomplete .MuiInputLabel-root {
  color: white;
}
.white-autocomplete .MuiInputBase-placeholder {
  color: white !important;
}
.white-autocomplete .MuiAutocomplete-clearIndicator {
  color: white;
}
.white-autocomplete .MuiAutocomplete-popupIndicatorOpen {
  color: white;
}
.white-autocomplete .MuiAutocomplete-endAdornment {
  color: white;
}
.white-autocomplete .MuiOutlinedInput-root {
  background-color: #3184fd;
}


@media screen and (max-width: 600px) {
  .col-25,
  .col-75,
  input[type="submit"] {
    width: 100%;
    margin-top: 0;
    margin-bottom: 5px !important;
  }
  .form-label {
    color: #5a5a5a;
    font-weight: 600;
    font-family: none;
    margin-bottom: -10px !important;
  }
  .base-container {
    width: auto !important;
  }

  .css-96uuyl {
    position: relative;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.15);
    margin-right: 16px;
    margin-left: 25px !important;
    width: 60% !important;
  }
}





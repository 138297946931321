body {
  background-color: #e9eff3;
}

/* .form{
    background-color: white;
    border-radius: 5px;
    width: 550px;
    margin: 20px auto;
    padding: 20px;
    height: 600px; */
.app {
  /* background-color: rgb(208, 64, 64); */
}
.bgl {
  background-color: white;
}
.field-col {
  background-color: #f5f5f5;
}
.b-app {
  background-color: #005580;
  width: 175px;
}
.head-t {
  font-family: sofia-pro, sans-serif !important;
  color: #005580;
}
.form-body {
  text-align: left;
  padding: 10px 10px;
}

.form-body > * {
  padding: 5px;
}

.form__label {
  width: 40%;
}
.footer {
  text-align: center;
}
.form_ddl {
  width: 40%;
}
.inputadding {
  padding-Left: 66px !important;
  }
@media (max-width: 520px) {
  .paddinginputleft_0 {
    padding-left: 14px !important;
  }
}

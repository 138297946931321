/* body {
  font-family: "Rubik", sans-serif;
  height: 100% !important;
} */
.vl {
  margin: 8px !important;
  width: 2px;
  border-right: 1px solid #aaaaaa;
  height: 25px;
}
.card {
  border-radius: 0px !important;
}

.card-left-border-warring {
  border-left: 3px solid #f4c414 !important;
}

.card-left-border-primary {
  border-left: 3px solid #448bff !important;
}

.card-left-border-danger {
  border-left: 3px solid #f54394 !important;
}
.card-left-border-success {
  border-left: 3px solid #08b97b !important;
}

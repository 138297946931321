body {
  background-color: #f4f3f3;
}

section {
  padding: 80px;
}
.hero {
  display: flex;
  flex-direction: row;
  width: 90%;
  margin: auto;
}

h1 {
  color: #000;
  font-size: 30px;
  font-family: none;
  padding-top: 0.5em;
}
.form-label {
  color: #5a5a5a;
  font-size: 1.1em;
  font-weight: 400;
  font-family: none;
  margin-top: 10px;
}

a {
  text-decoration: none;
  color: white;
}

a:hover {
  text-decoration: none;
  color: white;
}

.cta {
  background-color: #2668f4;
  padding: 8px 32px;
  border-radius: 4px;
  font-weight: 600;
  outline: none;
  text-transform: capitalize;
  font-size: 16px;
  box-sizing: border-box;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  min-width: 140px;
  position: relative;
  font-family: inherit;
  width: 190px !important;
}

.cta:hover {
  text-decoration: none;
  background: #f42626;
}

/* media queries */
@media screen and (max-width: 996px) {
  .hero {
    flex-direction: column;
    text-align: center;
    justify-content: center;
    padding: 30px;
    padding: 10px;
    width: 85%;
  }

  .hero img {
    order: 1;
    margin-bottom: 40px;
    display: none;
  }

  .home p {
    padding: 0 50px 0 50px;
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 470px) {
  .hero h1 {
    font-size: 1.5em;
  }
  .hero p {
    font-size: 1em;
  }
}
.ibox-content {
  background-color: #ffffff;
  color: inherit;
  padding: 10px 9px 0px 20px;
  border-color: #e7eaec;
  border-image: none;
  border-style: solid solid none;
  border-width: 1px 0;
}
.wp-features {
  padding: 0px 1px;
  margin-bottom: 25px;
}
.wp-form-heading,
.wp-heading {
  font-size: 25px;
  font-weight: 600;
}
.spacing-bottom {
  margin-bottom: 5px;
}
.wp-features-container,
.wp-signup-form-container {
  -webkit-flex-basis: 50%;
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}

.wp-features-container {
  line-height: 1.7;
}
.m-right {
  margin-right: 12px;
}

.NoBullets li {
  list-style: none;
}

ul li {
  font-size: 14px;
}
.wp-logo {
  height: auto;
  width: 70px;
  vertical-align: text-top;
}
.zp-signup-form-heading {
  margin-bottom: 10px;
  color: #fff;
}
.zp-signup-form-heading h2 {
  font-weight: 400;
}
.zp-form-heading,
.zp-heading {
  font-size: 28px;
  font-weight: 600;
}

:root {
  --color1: #0d6efd;
  --color2: #e59702;
}
.serviceBox {
  font-family: "Varela Round", sans-serif;
  text-align: center;
  padding: 0 0 10px;
  border-radius: 25px;
  background: linear-gradient(180deg, #edf6ff 0%, rgba(237, 246, 255, 0) 100%);

  /* box-shadow: 5px 0 40px -20px linear-gradient(180deg, #EDF6FF 0%, rgba(237, 246, 255, 0) 100%)  inset; */
}
.serviceBox .service-icon {
  color: #fff;
  /* background: linear-gradient(180deg, #EDF6FF 0%, rgba(237, 246, 255, 0) 100%); */

  margin-top: -1em;
  /* background: linear-gradient(var(--color1),var(--color1)); */
  font-size: 37px;
  text-align: center;
  line-height: 82px;
  width: 80px;
  height: 80px;
  /* margin: 0 0 25px; */
  border-radius: 40px 0;
  display: inline-block;
}
.serviceBox .title {
  color: var(--color2);
  font-size: 18px;
  font-weight: 600;
  font-style: italic;
  text-transform: capitalize;
  text-align: center;
  letter-spacing: 0.5px;
  margin: 0 15px 10px;
}
.serviceBox .description {
  color: #555;
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.5px;
  text-align: center;
  margin: 0 25px;
}
.serviceBox.magenta {
  --color1: #e757ac;
  --color2: #ba2680;
}
.serviceBox.purple {
  --color1: #946eb3;
  --color2: #6e438f;
}
.serviceBox.blue {
  --color1: #54d0e6;
  --color2: #30a5bd;
}
@media only screen and (max-width: 1199px) {
  .serviceBox {
    margin: 0 0 30px;
  }
}

.navbar-fixed-top {
  padding-top: 0px;
  background-color: rgba(242, 249, 255, 0.95) !important;
  -webkit-box-shadow: 0px 2px 5px -2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 2px 5px -2px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 2px 5px -2px rgba(0, 0, 0, 0.75) !important;
  animation: smoothScroll 1s forwards;
}

.sub_description {
  color: #003b73;
  text-align: center;
  line-height: 1.5;
  font-weight: 400;
  font-size: 16px;
}
.img_icon {
  height: auto;
  max-width: 100%;
  width: 120px;
  margin-top: -68px;
}
.rowflexbottom {
  display: flex;
  align-items: end;
  flex-wrap: wrap;
}
.text-control {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  padding: 0px 0px !important;
  text-indent: 10px;
}
.right-border-radius {
  border-radius: 0px 5px 5px 0px !important;
}
.all-border-radius {
  border-radius: 0px 0px 0px 0px !important;
}

/* Rahul header */
.sales__logo {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border-bottom: solid 1px #e4e4e4;
  background-color: #ffffff;
  padding: 15px 0;
}
.header__logo {
  height: 37px;
}
.pr-20 {
  padding-right: 20px !important;
}
.pr-10 {
  padding-right: 10px !important;
}
.awarded-medal img {
  width: 38px;
}
.awarded-medal {
  border-left: 1px solid #e4e4e4;
}

.pl-20 {
  padding-left: 20px !important;
}
.awarded-medal .text {
  line-height: 20px;
}
.pl-10 {
  padding-left: 10px !important;
}
.awarded-medal span {
  font-weight: 700;
}
a {
  background-color: transparent;
  color: #5605da;
}
.customerFileBtn {
  width: 100%;
  margin: auto;
}

.FormInputFile {
  background-color: transparent;
}

.customerFileBtn .FormInputFile {
  padding: 6px 0 0 7px;
  &::-webkit-file-upload-button {
    display: none;
  }
  &::file-selector-button {
    display: none;
  }
}

.customerFileBtn label:hover {
  background-color: #dde0e3;
  cursor: pointer;
}

.CR_input-padding{
  padding: 0.375rem 0.3rem !important;
}

@media only screen and (max-width: 600px) {
  .loginMobile_view{
    padding: 8% 6% !important;
    
  }
  .login_card_padding{
    padding: 13px !important;
  }
  .registration_fileupload{
    font-size: 12px !important;
      font-weight: 400!important;
  }
}
/*!
 * Bootstrap v4.0.0-beta (https://getbootstrap.com)
 * Copyright 2011-2017 The Bootstrap Authors
 * Copyright 2011-2017 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

.FaceContainer{
  max-width: 566px !important;
}

@media (min-width: 576px) {
  .FaceRecognitionContainer {
    max-width: 540px !important;
  }
}

@media (min-width: 768px) {
  .FaceRecognitionContainer {
    max-width: 720px !important;
  }
}

@media (min-width: 992px) {
  .FaceRecognitionContainer {
    max-width: 960px !important;
  }
}

@media (min-width: 1200px) {
  .FaceRecognitionContainer {
    max-width: 1140px !important;
  }
}

.wrap-login100 {
  border-radius: 10px;
  overflow: hidden;
  padding: 0;
  /* background: rgb(30 64 175); */
}

.FaceRecognitionBackgroundColor {
  background: rgb(30 64 175);
}

.model{
    margin-top: 10em;
    width: 500px;
    margin-left: 45em;
}


@media screen and ((min-width: 900px)and (max-width: 1000px)) {
    .model {width: 200px; float: left;}
  }

  .confirm-box{
    position: fixed !important;
  }
input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
.main-section {
  border: 1px solid #1976d2;
  background-color: #fff;
}
.profile-header {
  background-color: #1976d2;
  height: 150px;
}
.user-detail {
  margin: -50px 0px 30px 0px;
}
.user-detail img {
  height: 100px;
  width: 100px;
}
.company-img {
  height: 60px;
  width: 75px;
}
.user-detail h5 {
  margin: 15px 0px 5px 0px;
}
.user-social-detail {
  padding: 15px 0px;
  background-color: #1976d2;
}
.user-social-detail a i {
  color: #fff;
  font-size: 23px;
  padding: 0px 5px;
}
.IDcard {
  position: relative;
  /* width:90.2mm; */
  /* height: 50.8mm; */
}


.css-serial {
  counter-reset: serial-number;
  /* Set the serial number counter to 0 */
}

.css-serial td:first-child:before {
  counter-increment: serial-number;
  /* Increment the serial number counter */
  content: counter(serial-number);
  /* Display the counter */
}
.table-td-center {
  text-align: center;
  min-width: 50px;
}
.table-td-left {
  text-align: left !important;
  min-width: 50px !important;
  text-align: justify !important;
}
.table td {
  text-align: center;
  min-width: 50px;
}
.table th {
  text-align: center;
  font-style: normal;
  font-weight: 900;
}
.td-width-5 {
  width: 500px !important;
}
.td-width-3 {
  width: 300px !important;
}
.td-width-1 {
  width: 100px !important;
}
.td-width-2 {
  width: 200px !important;
}
.td-width-50 {
  width: 50px !important;
}
.td-width-80 {
  width: 80px !important;
}

/* Rahul */
.landing__header h1 {
  font-size: 42px;
  font-weight: 700;
  line-height: 1.21;
  color: #102746;
  margin-top: 0;
  margin-bottom: 0;
  -webkit-font-smoothing: auto;
  font-family: "Montserrat", sans-serif;
}

.zp-signup-form {
  /* width: 75%; */
  margin: auto;
  margin-top: 20px;
  text-align: center;
  padding: 25px 29px;
  border-top: 2px solid #0b0f9f;
  /* border-top: 2px solid #f55d49; */
  border-radius: 10px;
  -webkit-box-shadow: 0 4px 11px 0 #e4e4e4;
  box-shadow: 0 04px 0px 0 #e4e4e4;
  background: #fff;
}
.zp-signup-form-heading h2 {
  font-weight: 400;
}
.zp-form-heading,
.zp-heading {
  font-size: 28px;
  font-weight: 600;
}
.form-group input[type],
.form-group select {
  background-size: 15px 15px;
  background-position: 0;
}
.ZP-form input[type],
.ZP-form select {
  width: 100%;
  outline: none;
  border: none;
  font-family: inherit;
  font-size: 16px;
  padding: 5px 25px;
}
.form-group .form-control {
  -webkit-box-shadow: 0 1px 0 #eaeaea;
  box-shadow: 0 1px 0 #eaeaea;
}
.slideshow p {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.38;
  color: #55595e;
  margin: 0;
  -webkit-font-smoothing: auto;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 5px;
}
.landing__header {
  padding: 50px 0 50px;
}
.contact__form.slideshow,
.landing__header.slideshow {
  background-position: right top;
}
.slideshow {
  /* background: linear-gradient(rgba(255,255,255,.65), rgba(255,255,255,.65)), url(https://hrone.cloud/wp-content/themes/hrone/inc/assets/images/fill-bg-sm.png); */
  background-size: 39%;
  background-repeat: no-repeat;
  background-position: right bottom;
  padding: 20px 0 0;
  background-color: #020669;
  margin: 20px !important;
  border-radius: 10px;
  padding-bottom: 30px;
}

.check__list,
.flag__list {
  /* padding: 0; */
  margin: 0;
  padding: 0 0 20px 0;
}

.check__list li {
  background-image: url(https://hrone.cloud/wp-content/themes/hrone/inc/assets/images/software/check.svg);
  background-position: left top 6px;
  background-size: 18px;
  font-size: 16px;
  color: #55595e;
  line-height: 1.9em;
}
.check__list li,
.flag__list li {
  margin: 0 0 10px 0;
  padding: 0 0 0 30px;
  list-style: none;
  background-repeat: no-repeat;
  font-weight: 400;
  margin: 0;
  -webkit-font-smoothing: auto;
  font-family: "Montserrat", sans-serif;
  color: #68e8f4;
}

@media (min-width: 992px) {
  .check__list.block li {
    display: block;
  }
}
@media (min-width: 992px) {
  .check__list li,
  .flag__list li {
    display: inline-block;
    min-width: 140px;
  }
}


.za-employees-container select {
  background: url("https://assets.wiseofficeday.com/wod/project_images/employees.svg") no-repeat;
  background-size: 15px 15px;
  background-position: left;
}

.za-website-container input {
  background: url("https://assets.wiseofficeday.com/wod/project_images/website.svg") no-repeat;
}
.za-user-container input {
  background: url("https://assets.wiseofficeday.com/wod/project_images/user.svg") no-repeat;
}

.za-name-container input {
  background: url("https://assets.wiseofficeday.com/wod/project_images/organization.svg") no-repeat;
}
.za-email-container input {
  background: url("https://assets.wiseofficeday.com/wod/project_images/email.svg") no-repeat;
}
.za-contact-number-container input,
.za-rmobile-container input {
  background: url("https://assets.wiseofficeday.com/wod/project_images/phone.svg") no-repeat;
}
.za-password-container input {
  background: url("https://assets.wiseofficeday.com/wod/project_images/password.svg")
    no-repeat;
}
.za-country select {
  background: url("https://assets.wiseofficeday.com/wod/project_images/global.svg") no-repeat;
  background-size: 15px 15px;
  background-position: left;
}
.landing__header h3 {
  font-size: 28px;
  font-weight: 700;
  color: #00b59d;
}
.landing__stars {
  border: 1px solid #eee;
  border-radius: 2px;
  box-shadow: 0 0px 0px 0px #e0dede;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 5px;
}

element.style {
  opacity: 1;
}
.zp-form-submit {
  color: #fff;
  background: #0b0f9f;
  padding: 9px 21px;
  border: none;
  border-radius: 500px;
  outline: none;
  font-size: 16px;
  width: 80%;
  cursor: pointer;
}

button {
  font-family: zoho_puvi, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
}
.za-dc-container {
  font-size: 13px;
  text-align: left;
  color: #55595e;
}

@media screen and (max-width: 600px) {
  .zp-main-content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
    width: 100%;
  }
}

/* content */
.landing__software.blue {
  background-color: #f9fffe;
}
.landing__software.software__mobile {
  background-color: #f9fffe;
  padding: 20px 0;
}
.landing__software p {
  font-family: "Montserrat", sans-serif;
}
.software__mobile {
  background-color: #f9fffe;
  padding: 80px 0;
}
.software__mobile--first {
  margin-bottom: 40px;
}
/* .software__mobile--convenience {
    max-width: 200px;
} */
.software__mobile h2 {
  font-size: 42px;
  font-weight: 700;
  color: #102746;
  margin-top: 0;
}
.software__mobile p {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #102746;
}

.landing__suite {
  padding: 50px 0;
}
.landing__suite h2 {
  font-size: 42px;
  font-weight: 700;
  line-height: 1.21;
  /* color: #33415a; */
  color: #102746;
  margin-bottom: 0;
}
.landing__suite p {
  font-family: "Montserrat", sans-serif;
}

.landing__demo {
  padding: 10px 0 80px;
}

.landing__bottom {
  color: #102746;
  font-weight: 400;
}
.landing__bottom {
  padding: 0px 0 50px;
}
.landing__bottom h4 {
  font-size: 18px;
  font-weight: 600;
}
.landing__bottom p {
  margin-bottom: 1.5em;
  font-family: "Montserrat", sans-serif;
}
.landing__footer {
  background-color: #f8fafd;
  padding: 50px 0 40px;
}
.landing__footer h4 {
  font-size: 16px;
  line-height: 1.35;
  font-weight: 600;
}
.landing__footer p {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
  color: #102746;
  margin: 5px 0;
  font-family: "Montserrat", sans-serif;
}
.landing__copyright {
  background-color: #f8fafd;
}
.site-footer .site-info {
  border-top: solid 1px #d1d5df;
  padding: 9px 0;
}
.landing__copyright p {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
  color: #102746;
  margin: 5px 0;
  font-family: "Montserrat", sans-serif;
}


/* footer */
.footer-section {
  background: #ffffff;
  position: relative;
}

.footer-cta {
  border-bottom: 1px solid #9c9999;
  padding-bottom: 8px !important;
}

.single-cta i {
  color: #ff5e14;
  font-size: 30px;
  float: left;
  margin-top: 8px;
}

.cta-text {
  padding-left: 15px;
  display: inline-block;
}

.cta-text h4 {
  color: #070707;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2px;
}

.cta-text span {
  color: #757575;
  font-size: 15px;
}

.footer-content {
  position: relative;
  z-index: 2;
}

.footer-pattern img {
  position: absolute;
  top: 0;
  left: 0;
  height: 330px;
  background-size: cover;
  background-position: 100% 100%;
}

.footer-logo img {
  max-width: 120px;
}

.footer-text p {
  margin-bottom: 14px;
  font-size: 14px;
  color: #7e7e7e;
  line-height: 21px;
}

.footer-social-icon span {
  color: #000000;
  display: block;
  font-size: 20px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  margin-bottom: 20px;
}

.footer-social-icon a {
  color: #fff;
  font-size: 16px;
  margin-right: 15px;
}

.footer-social-icon i {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 38px;
  border-radius: 50%;
}

.facebook-bg {
  background: #3b5998;
}

.twitter-bg {
  background: #55acee;
}

.google-bg {
  background: #dd4b39;
}

.footer-widget-heading h3 {
  color: #0f0f0f;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 40px;
  position: relative;
}

.footer-widget-heading h3::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -15px;
  height: 2px;
  width: 50px;
  background: #ff5e14;
}

.footer-widget ul li {
  display: inline-block;
  float: left;
  width: 100%;
  margin-bottom: 12px;
}

.footer-widget ul li a:hover {
  color: #ff5e14;
}
.footer-widget ul {
padding-left: 0.3rem !important;
}
.footer-widget ul li a {
  color: #878787 !important;
  text-transform: capitalize;
}


.footer-menu li {
  display: inline-block;
  margin-left: 20px;
}

.footer-menu li:hover a {
  color: #ff5e14;
}

.footer-menu li a {
  font-size: 14px;
  color: #ffffff;
}

.footer-cta .row .col-xl-4:nth-child(1) .single-cta i {
  position: absolute;
  color: #ff5e14;
}

.footer-cta .row .col-xl-4:nth-child(1) .single-cta .cta-text {
  padding-left: 32px;
}

.footer-cta .row .col-xl-4:nth-child(2) .single-cta i {
  color: #14ffa7;
}

.footer-cta .row .col-xl-4:nth-child(3) .single-cta i {
  color: #ffd700;
}

.footer-social-icon .social_icon {
  text-align: left;
}

.footer-social-icon .social_icon li {
  float: inherit !important;
  display: inline-block !important;
  width: inherit;
}

.footer-social-icon .social_icon li a i {
  font-size: 15px;
  line-height: 39px;
}

.footer-social-icon .social_icon li a {
  width: 40px;
  height: 40px;
}

.social_icon {
  text-align: right;
  margin: 0;
  margin-top: 3px;
}

.social_icon li {
  display: inline-block;
  margin: 0 5px;
}

.social_icon li a {
  display: inline-block;
  width: 25px;
  height: 25px;
  background: #fff;
  color: #000;
  text-align: center;
  border-radius: 100%;
  transition: 0.2s ease-in-out;
}

.social_icon li a i {
  font-size: 15px;
  line-height: 20px;
}

.social_icon li:nth-child(1) a {
  background: #4267b2;
  color: #fff;
}

.social_icon li:nth-child(2) a {
  background: #1da1f2;
  color: #fff;
}

.social_icon li:nth-child(3) a {
  background: #f09433;
  background: -moz-linear-gradient(45deg,
      #f09433 0%,
      #e6683c 25%,
      #dc2743 50%,
      #cc2366 75%,
      #bc1888 100%);
  background: -webkit-linear-gradient(45deg,
      #f09433 0%,
      #e6683c 25%,
      #dc2743 50%,
      #cc2366 75%,
      #bc1888 100%);
  background: linear-gradient(45deg,
      #f09433 0%,
      #e6683c 25%,
      #dc2743 50%,
      #cc2366 75%,
      #bc1888 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f09433', endColorstr='#bc1888', GradientType=1);
  color: #fff;
}

.social_icon li:nth-child(4) a {
  background: #ff0000;
  color: #fff;
}

.social_icon li:nth-child(1):hover a {
  background: #fff;
  color: #4267b2;
}

.social_icon li:nth-child(2):hover a {
  background: #fff;
  color: #1da1f2;
}

.social_icon li:nth-child(3):hover a {
  background: #fff;
  color: #bc1888;
}

.social_icon li:nth-child(4):hover a {
  background: #fff;
  color: #ff0000;
}

.profile-image-container {
  position: relative;
  width: 120px;
  height: 120px;
}

.profile-image-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 50%;
  border: 4px solid #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.profile-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.upload-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #f0f0f0;
  color: #888;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  word-wrap: break-word;
}

.file-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.upload-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.profile-image-wrapper:hover .upload-button {
  opacity: 1;
}

.gradient-custom-2 {
  /* fallback for old browsers */
  background: #fccb90;

  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(
    to right,
    #ee7724,
    #d8363a,
    #dd3675,
    #b44593
  );

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(to right, #ee7724, #d8363a, #dd3675, #b44593);
}

/* @media (min-width: 768px) {
  .gradient-form {
    height: 100vh !important;
  }
} */
/* @media (min-width: 769px) {
  .gradient-custom-2 {
    border-top-right-radius: 0.1rem;
    border-bottom-right-radius: 0.1rem;
  }
} */

/* body{
    background-color: #6F9CC2;
}
.app{
    background-color: #fefefe;
}
.bapp{
    background-color: #2399f1;
}
.head-t{
    font-family: sofia-pro, sans-serif !important;
color: #005580;
}
.text-color{
    color: #2399f1;
} */

body {
  color: #000;
  overflow-x: hidden;
  height: 100%;
  background-color: #ffffff;
  background-repeat: no-repeat;
}

.card0 {
  box-shadow: 0px 4px 8px 0px #757575;
  border-radius: 0px;
}
.card3 {
  box-shadow: 0px;
  border-radius: 0px;
}

.logo {
  width: 170px !important;
  height: auto;
  margin-top: 20px !important;
  margin-left: 35px;
  margin-bottom: -2em;
}

.image {
  width: 360px;
  height: 280px;
}

.border-line {
  border-right: 1px solid #eeeeee;
}

.facebook {
  background-color: #3b5998;
  color: #fff;
  font-size: 18px;
  padding-top: 5px;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  cursor: pointer;
}

.twitter {
  background-color: #1da1f2;
  color: #fff;
  font-size: 18px;
  padding-top: 5px;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  cursor: pointer;
}

.linkedin {
  background-color: #2867b2;
  color: #fff;
  font-size: 18px;
  padding-top: 5px;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  cursor: pointer;
}

.line {
  height: 1px;
  width: 35% !important;
  background-color: #e0e0e0;
  margin-top: 10px !important;
}

.or {
  width: 20% !important;
  font-weight: bold;
}

.text-sm {
  font-size: 14px !important;
}

::placeholder {
  color: #bdbdbd;
  opacity: 1;
  font-weight: 300;
}

:-ms-input-placeholder {
  color: #bdbdbd;
  font-weight: 300;
}

::-ms-input-placeholder {
  color: #bdbdbd;
  font-weight: 300;
}

input,
textarea {
  padding: 10px 12px 10px 12px;
  border: 1px solid lightgrey;
  border-radius: 2px;
  margin-bottom: 5px;
  margin-top: 2px;
  width: 100%;
  box-sizing: border-box;
  color: #2c3e50;
  font-size: 14px;
  letter-spacing: 1px;
}

input:focus,
textarea:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 1px solid #304ffe;
  outline-width: 0;
}

button:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline-width: 0;
}

a {
  color: inherit !important;
  cursor: pointer;
}

.btn-blue {
  background-color: #1a237e;
  width: 150px;
  color: #fff;
  border-radius: 2px;
}

.btn-blue:hover {
  background-color: #000;
  cursor: pointer;
}

.bg-blue {
  color: #fff;
  background-color: #1a237e;
}

@media screen and (max-width: 991px) {
  .logo {
    margin-left: 11px;
  }

  .image {
    width: 100px;
    height: 220px;
  }

  .border-line {
    border-right: none;
  }
  .borderRadius {
    border-radius: 10px !important;
  }

  .card2 {
    border-top: 0px solid #eeeeee !important;
    margin: 0px 30px;
    box-shadow: 0px 4px 8px 0px #eeeeee;
  }
}

@import url("https://fonts.googleapis.com/css?family=Montserrat");

.pricing-table {
  background-color: #eee;
  font-family: "Montserrat", sans-serif;
}

.pricing-table .block-heading {
  padding-top: 20px;
  margin-bottom: 40px;
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
}

.pricing-table .block-heading h2 {
  color: #3b99e0;
}

.pricing-table .block-heading p {
  text-align: center;
  max-width: 420px;
  margin: auto;
  opacity: 0.7;
}

.pricing-table .heading {
  text-align: center;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 10px;
  background-color: #0d6efd;
  vertical-align: middle;
  height: 100px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-weight: bold !important;
  color: #ffffff !important;
  font-size: 25pt;
}

.pricing-table .heading-text {
  text-align: center;
  font-weight: bold !important;
  color: #ffffff !important;
  font-size: 25pt;
}

.pricing-table .item {
  background-color: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.075);
  padding: 20px;
  overflow: hidden;
  position: relative;
  align-items: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.pricing-table .col-md-5:not(:last-child) .item {
  margin-bottom: 30px;
}

.pricing-table .item button {
  font-weight: 600;
}

.pricing-table .ribbon {
  width: 160px;
  height: 32px;
  font-size: 12px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  box-shadow: 0px 2px 3px rgba(136, 136, 136, 0.25);
  background: #4dbe3b;
  transform: rotate(45deg);
  position: absolute;
  right: -42px;
  top: 20px;
  padding-top: 7px;
}

.pricing-table .item p {
  text-align: center;
  margin-top: 20px;
  opacity: 0.7;
}

.pricing-table .features .feature {
  font-weight: 600;
}

.pricing-table .features {
  font-size: 15px;
  padding: 0px;
  margin-top: 15px;
}

.pricing-table .price h4 {
  margin-top: 15px;
  margin-bottom: 0px;
  font-size: 45px;
  font-weight: 600;
  text-align: center;
  color: #2288f9;
}

.pricing-table .buy-now button {
  text-align: center;
  margin: auto;
  font-weight: 600;
  padding: 9px 0;
}
.span-paisa {
  font-size: 20pt;
}
.pricing-plan-period {
  font-size: 10pt;
  text-align: center;
}
bgcolor-subscription-page {
  background-color: #eeeeee !important;
}

.ws-button {
  background-color: white;
  color: black;
  padding: 14px 28px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 10px;
}

/* CARDS */

.card {
  box-shadow: 0 20px 27px 0 rgb(0 0 0 / 5%);
  border-radius: 10px !important;
}
.img-md {
  width: 4rem;
  height: 4rem;
}

.active-subscription {
  background: rgb(13, 110, 253) !important;
  color: white !important;
}

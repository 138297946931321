body {
  background: #eee;
}

.card-cd {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 10px !important;
}
.img-50 {
  height: 50px !important;
  width: auto !important;
}
.img-40 {
  height: 40px !important;
  width: auto !important;
}
.card-body-cd {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.5rem 1.5rem;
}
.avatar-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #000;
  color: #fff;
  font-weight: 700;
}

.avatar {
  width: 3rem;
  height: 3rem;
}
.rounded-3 {
  border-radius: 0.5rem !important;
}
.mb-2 {
  margin-bottom: 0.5rem !important;
}
.me-4 {
  margin-right: 1.5rem !important;
}

.font-size-13 {
  font-size: 13px !important;
}

.font-size-30 {
  font-size: 30px !important;
  font-weight: bold !important;
}
.card-employee-holyday {
  padding: 5px !important;
  padding-left: 10px !important;
}

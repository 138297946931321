.blue_input::placeholder {
    color: white; /* Set placeholder text to white */
  }
 
.blue_input{
    background-color: rgb(49 132 253);
    color:white;
    
}

.blue_input:focus{
    background-color: rgb(49 132 253);
    color:white;
}
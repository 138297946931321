body {
  background: #eee;
}

.card-cd {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 10px !important;
}
.img-50 {
  height: 60px !important;
  width: auto !important;
}
.img-set50 {
  height: 50px !important;
  width: 50px !important;
  border-radius: 50% !important;
}

.img-set100 {
  height: 130px !important;
  width: 130px !important;
  align-items: center !important;
  justify-content: center !important;
  display: flex !important;
}
.img-40 {
  height: auto !important;
  width: 40px !important;
}
.card-body-cd {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.5rem 1.5rem;
}
.avatar-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #000;
  color: #fff;
  font-weight: 700;
}

.avatar {
  width: 3rem;
  height: 3rem;
}
.rounded-3 {
  border-radius: 0.5rem !important;
}
.mb-2 {
  margin-bottom: 0.5rem !important;
}
.me-4 {
  margin-right: 1.5rem !important;
}
.font-size-10 {
  font-size: 10px !important;
}
.font-size-15 {
  font-size: 15px !important;
}
.font-size-13 {
  font-size: 13px !important;
}
.font-size-16 {
  font-size: 16px !important;
}

.font-size-20 {
  font-size: 30px !important;
  font-weight: 600;
}
.card-employee-holyday {
  padding: 5px !important;
  padding-left: 10px !important;
}

/* Tab section */
.ws-nav-button {
  color: #00c49f !important;
  font-weight: 600;
}

.ws-nav-button :hover {
  text-decoration: underline !important;
  color: #0088fe !important;
  font-weight: bold;
}

.MuiToolbar-root p {
  margin-bottom: 0px !important;
}
.birthday-image {
  background-image: url(https://assets.wiseofficeday.com/wod/project_images/WiseHappibirthday2.jpg);
  background-repeat: no-repeat;
  /* background-size: 300px 320px; */
  background-size: cover;
}

.anniversaries-image {
  background-image: url(https://assets.wiseofficeday.com/wod/project_images/Annyvesory.jpg);
  background-repeat: no-repeat;
  /* background-size: 300px 320px; */
  background-size: cover;
}
.anniversaries-background {
  background-color: #00c49f;
}

.carousel-indicators button {
  background-color: #454545 !important;
}

.carousel-indicators .active {
  background-color: #0088fe !important;
}
.carousel-indicators {
  margin: 0 10px !important;
  display: none !important;
}

.thumbnail-gallery .active {
  border-color: #d10057 !important;
}

.BirsthayName {
  padding: 10px !important;
  margin-bottom: 50px !important;
  color: #eee;
  font-weight: 600;
  background-color: rgba(2, 28, 46, 0.7);
  text-align: center;
}

.AnnyvName {
  padding: 10px !important;
  margin-bottom: 50px !important;
  color: #eee;
  font-weight: 600;
  background-color: rgba(114, 6, 33, 0.7);
  text-align: center;
}
@media (min-width:320px) {
  .mblImg{
    padding-top: 20px !important;
    width: 33.3% !important;
  }
}

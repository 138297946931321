Mtable {
    border: 1px solid #b3adad !important;
    border-collapse: collapse;
    padding: 5px;
}

Mtable th {
    border: 1px solid #b3adad;
    padding: 5px;
    background: #f0f0f0;
    color: #313030;
}

Mtable td {
    border: 1px solid #b3adad;
    text-align: center;
    padding: 5px;
    background: #ffffff;
    color: #313030;
}
.textAlignLeft{
    text-align: left;
}
.textAlignRight {
    text-align: right;
}
.textAlignCenter {
    text-align: center;
}
.backGroundGray{
    background: #D9D9D9;
}
.italic{
    font-style: italic;
    font-style: oblique;
}
.background-orange{
    background: #FDE9D9; 
}


body {
  background: #eee;
}

.card-cd {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 10px !important;
}
.img-50 {
  height: 50px !important;
  width: auto !important;
}
.img-40 {
  height: 40px !important;
  width: auto !important;
}
.card-body-cd {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.5rem 1.5rem;
}
.avatar-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #000;
  color: #fff;
  font-weight: 700;
}

.avatar {
  width: 3rem;
  height: 3rem;
}
.rounded-3 {
  border-radius: 0.5rem !important;
}
.mb-2 {
  margin-bottom: 0.5rem !important;
}
.me-4 {
  margin-right: 1.5rem !important;
}

.font-size-13 {
  font-size: 13px !important;
}

.card-employee-holyday {
  padding: 5px !important;
  padding-left: 10px !important;
}

/* Contact Us */

card.user-card {
  border-top: none;
  -webkit-box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.05),
    0 -2px 1px -2px rgba(0, 0, 0, 0.04), 0 0 0 -1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.05),
    0 -2px 1px -2px rgba(0, 0, 0, 0.04), 0 0 0 -1px rgba(0, 0, 0, 0.05);
  -webkit-transition: all 150ms linear;
  transition: all 150ms linear;
}

.card {
  border-radius: 5px;
  -webkit-box-shadow: 0 1px 2.94px 0.06px rgba(4, 26, 55, 0.16);
  box-shadow: 0 1px 2.94px 0.06px rgba(4, 26, 55, 0.16);
  border: none;
  margin-bottom: 30px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.card .card-header {
  background-color: transparent;
  border-bottom: none;
  padding: 25px;
}

.card .card-header h5 {
  margin-bottom: 0;
  color: #222;
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  margin-right: 10px;
  line-height: 1.4;
}

.card .card-header + .card-block,
.card .card-header + .card-block-big {
  padding-top: 0;
}

.user-card .card-block {
  text-align: center;
}

.card .card-block {
  padding: 20px;
}

.user-card .card-block .user-image {
  position: relative;
  margin: 0 auto;
  padding: 5px;
}

.img-radius {
  border-radius: 50%;
}

.f-w-600 {
  font-weight: 600;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-t-25 {
  margin-top: 25px;
}

.m-t-15 {
  margin-top: 15px;
}

.card .card-block p {
  line-height: 1.4;
}

.text-muted {
  color: #919aa3 !important;
}

.user-card .card-block .activity-leval li.active {
  background-color: #2ed8b6;
}

.user-card .card-block .activity-leval li {
  display: inline-block;
  width: 15%;
  height: 4px;
  margin: 0 3px;
  background-color: #ccc;
}

.user-card .card-block .counter-block {
  color: #fff;
}

.bg-c-blue {
  background: linear-gradient(45deg, #4099ff, #73b4ff);
}

.bg-c-green {
  background: linear-gradient(45deg, #2ed8b6, #59e0c5);
}

.bg-c-yellow {
  background: linear-gradient(45deg, #ffb64d, #ffcb80);
}

.bg-c-pink {
  background: linear-gradient(45deg, #ff5370, #ff869a);
}

.m-t-10 {
  margin-top: 10px;
}

.p-20 {
  padding: 20px;
}

.user-card .card-block .user-social-link i {
  font-size: 30px;
}

.text-facebook {
  color: #3b5997;
}

.text-twitter {
  color: #42c0fb;
}

.text-dribbble {
  color: #ec4a89;
}

/* --customise -- */
.css-sxh3gq-MuiAvatar-root-MuiAvatarGroup-avatar{
height: 30px !important;
width: 30px !important;
}


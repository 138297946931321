.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.register {
  overflow: hidden;
}

.register .register-form {
  padding: 10%;
  margin-top: 10%;
}

@media (max-width: 991px) {
  .register .register-form {
    margin-top: 16%;
  }
}

@media (max-width: 667px) {
  .register .register-form {
    margin-top: 20%;
  }
}

.register .nav-tabs {
  margin-top: 3%;
  border: none;
  border-radius: 1.5rem;
  width: 28%;
  float: right;
}

@media (max-width: 991px) {
  .register .nav-tabs {
    width: 33%;
    margin-top: 8%;
  }
}

.register .nav-tabs .nav-link {
  padding: 2%;
  height: 34px;
  font-weight: 600;
  color: #fff;
  border-top-right-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
}

.register .nav-tabs .nav-link:hover {
  border: none;
}

.register .nav-tabs .nav-link.active {
  width: 100px;
  color: #0062cc;
  border: 2px solid #0062cc;
  border-top-left-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
}

.register-left {
  text-align: center;
  color: #fff;
  margin-top: 4%;
}

.register-left input {
  border: none;
  border-radius: 1.5rem;
  padding: 2%;
  width: 60%;
  background: #f8f9fa;
  font-weight: bold;
  color: #383d41;
  margin-top: 30%;
  margin-bottom: 3%;
  cursor: pointer;
}

.register-left img {
  margin-top: 15%;
  margin-bottom: 5%;
  width: 25%;
  animation: mover 1s infinite alternate;
}

.register-left p {
  font-weight: lighter;
  padding: 12%;
  margin-top: -9%;
}

.register-right {
  background: #f8f9fa;
  border-top-left-radius: 10% 50%;
  border-bottom-left-radius: 10% 50%;
}

@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-20px);
  }
}

@keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-20px);
  }
}

.btnRegister {
  float: right;
  margin-top: 10%;
  border: none;
  border-radius: 1.5rem;
  padding: 2%;
  background: #0062cc;
  color: #fff;
  font-weight: 600;
  width: 50%;
  cursor: pointer;
}

.register-heading {
  text-align: center;
  color: #005580;
}

@media (max-width: 991px) {
  .register-heading {
    font-size: 1.5rem;
  }
}

.img-circle {
  border-radius: 50%;
  height: 100px;
}

.form-group {
  padding-bottom: 10px;
}

.pt-110 {
  padding-top: 110px;
}

.base-container {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  border-radius: 0.25rem;
  margin-bottom: 1.5rem;
  box-shadow: 0 2px 6px 0 rgb(218 218 253 / 65%),
    0 2px 6px 0 rgb(206 206 238 / 54%);

  padding: 0px !important;
  background-color: #fff !important;
  margin: 10px !important;
}
.primary-bg-color {
  background-color: #0d6efd !important;
}

.nav-tabs {
  --bs-nav-tabs-border-width: 2px !important;
  --bs-nav-tabs-border-color: #dee2e6;
  --bs-nav-tabs-border-radius: 0.375rem;
  --bs-nav-tabs-link-hover-border-color: #e9ecef #e9ecef #ffffff !important;
  --bs-nav-tabs-link-active-color: #495057;
  --bs-nav-tabs-link-active-bg: #d7e9fc !important;
  --bs-nav-tabs-link-active-border-color: #1976d2 #1976d2 #ffffff !important;
  border-bottom: var(--bs-nav-tabs-border-width) solid
    var(--bs-nav-tabs-border-color);
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  font-weight: bold !important;
}
.my-modal-90 {
  width: 90vh !important;
}
.form-control:focus {
  box-shadow: none !important;
}
.search-icon {
  position: absolute;
  right: 5px;
  top: 8px;
  z-index: 9999;
}

.input-group:focus-within .form-control {
  box-shadow: none;
  border-color: none !important;
}
.base-margin-x {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.base-margin-2y {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}
.css-1k51tf5-MuiTooltip-tooltip {
  background-color: rgb(247 13 13 / 92%) !important;
  border-radius: 4px;
  color: #fff;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  padding: 4px 8px;
  font-size: 0.6875rem;
  max-width: 300px;
  margin: 0;
  word-wrap: break-word;
  font-weight: 500;
  position: relative;
}

.css-kudwh-MuiTooltip-arrow {
  overflow: hidden;
  position: absolute;
  width: 1em;
  height: 0.71em;
  box-sizing: border-box;
  color: rgb(247 13 13 / 92%) !important;
}

.css-levciy-MuiTablePagination-displayedRows {
  margin-top: 16px !important;
}

.max-width-input {
  max-width: 100px;
}

.card-team {
  border: 1 solid !important;
  border-radius: 0.25rem;
  margin-bottom: 0px !important;
  box-shadow: none !important;
  width: 100% !important;
}
.input-group:focus-within {
  box-shadow: none !important;
}
.footer-widget {
  height: 100% !important;
}
.default-background-blue {
  background-color: #1976d2;
}

.error-template {
  padding: 0px 15px;
  text-align: center;
}
.error-actions {
  margin-top: 15px;
  margin-bottom: 15px;
}
.error-actions .btn {
  margin-right: 10px;
}
.norecord-image {
  width: 200px !important;
  height: auto;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
/* Pagination */
.MuiTablePagination-toolbar {
  display: flex !important;
  align-items: baseline !important;
}
.page-background {
  background-color: #f4f3f3;
}
.ws-font-14 {
  font-family: sans-serif !important;
  font-size: 14px !important;
  color: #5a5a5a;
  font-weight: 400;
}

.ws-font-10 {
  font-family: sans-serif !important;
  font-size: 12px !important;
  color: #454545;
  font-weight: 400;
}

.ws-bg-grey-light {
  background-color: #f7f7f7;
}
.ws-bg-grey-light-2 {
  background-color: #a6a6a6;
}

/* Color Codes */

.ws-warning {
  color: #f4c414 !important;
}
.ws-primary {
  color: #0d6efd !important;
}
.ws-danger {
  color: #f54394 !important;
}
.ws-success {
  color: #08b97b !important;
}

.ws-bg-warning {
  background-color: #f4c414 !important;
}
.ws-bg-primary {
  background-color: #448bff !important;
}
.ws-bg-danger {
  background-color: #f54394 !important;
}
.ws-bg-success {
  background-color: #08b97b !important;
}

.ws-bdr-warning {
  border-color: #f4c414 !important;
}
.ws-bdr-primary {
  border-color: #448bff !important;
}
.ws-bdr-danger {
  border-color: #f54394 !important;
}
.ws-bdr-success {
  border-color: #08b97b !important;
}

/* SWAL Buttons Style */

/*  style overall buttons */
.swal-button {
  border: 1px dashed #333;
}
/*  style cancel buttons */
.swal-button--cancel {
  color: #333;
}
.swal2-confirm {
  color: white !important;
  background-color: #0d6efd !important;
  border: 1px solid #0d6efd !important;;

}
.swal2-confirm:hover {
  border: 1 !important;
  border-radius: 0.25em !important;
  color: #ffffff !important;
  background-color: #5691e9 !important;
}

.swal2-deny{
  border: 1px solid #0d6efd !important;;
  border-radius: 0.25em !important;
  color: #0d6efd !important;
  background-color: transparent !important;
}
.swal2-deny:hover{
  border: 1 !important;
  border-radius: 0.25em !important;
  color: #ffffff !important;
  background-color: #0d6efd !important;
}
.swal-button--danger {
  color: red;
}

.tab_Custome {
  -webkit-tap-highlight-color: transparent;
  /* background-color: hsl(249, 88%, 13%) !important;     */
  background-color: #0062cc !important;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  max-width: none;
  min-width: 90px;
  position: relative;
  min-height: 72px;
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  color: #fff !important;
  min-height: 55px !important;
  border-radius: 0px !important;
  border-right: 3px solid #fff !important;
}
.Maintab_Custome {
  background-color: #fff !important;
}

tbody,
td,
tfoot,
th,
thead,
tr {
  font-size: 0.8rem !important;
}
/* .input-group {
  align-items: center !important;
  display: flex !important;
} */

.table-row-card-layout{
    border-collapse: separate !important;
    border-spacing: 0px 15px !important;
    background-color: #f4f8f9;
    padding-left: 20px !important;
    padding-right: 20px !important;
}
.table-row-card-layout tr {
    background-color: white;
}
.table-row-card-layout td {
    height: 100px;
}
.table-row-card-layout th{
    vertical-align: inherit !important;
    white-space: nowrap;
}
.table-row-card-layout td:first-child,
.table-row-card-layout th:first-child {
    border-radius: 10px 0 0 10px;
}
.table-row-card-layout td:last-child,
.table-row-card-layout th:last-child {
    border-radius: 0 10px 10px 0;
}
.ql-snow .ql-tooltip{
    top: 25px !important;
    left: 0 !important;
}

.react-datepicker__time-box ul{
    overflow-y: visible !important;
}
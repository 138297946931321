.tax-invoice {
  margin: 20px auto;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
}

.invoice-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  font-weight: bolder;
  font-size: 1.5rem;
  color: #0d6efd;
}

.invoice-header h1 {
  margin: 0;
  font-size: 28px;
}

.invoice-details {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.invoice-info,
.client-info {
  flex: 1;
}

.client-info h2 {
  margin-top: 0;
}

.invoice-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

th,
td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: center;
}

th {
  background-color: #f2f2f2;
}

tfoot td {
  text-align: right;
  font-weight: bold;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

tr:hover {
  background-color: #ddd;
}

.invoice-footer {
  text-align: right;
  font-size: 14px;
}

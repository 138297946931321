.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.btn-a-brc-tp:not(.disabled):not(:disabled).active,
.btn-brc-tp,
.btn-brc-tp:focus:not(:hover):not(:active):not(.active):not(.dropdown-toggle),
.btn-h-brc-tp:hover,
.btn.btn-f-brc-tp:focus,
.btn.btn-h-brc-tp:hover {
  border-color: transparent;
}

.btn-outline-blue {
  color: #0d6ce1;
  border-color: #5a9beb;
  background-color: transparent;
}

.btn {
  cursor: pointer;
  position: relative;
  z-index: auto;
  border-radius: 0.175rem;
  transition: color 0.15s, background-color 0.15s, border-color 0.15s,
    box-shadow 0.15s, opacity 0.15s;
}

.border-2 {
  border-width: 2px !important;
  border-style: solid !important;
  border-color: transparent;
}

.bgc-white {
  background-color: #fff !important;
}

.text-green-d1 {
  color: #277b5d !important;
}

.letter-spacing {
  letter-spacing: 0.5px !important;
}

.font-bolder,
.text-600 {
  font-weight: 600 !important;
}

.text-170 {
  font-size: 1.7em !important;
}

.text-purple-d1 {
  color: #6d62b5 !important;
}

.text-primary-d1 {
  color: #276ab4 !important;
}

.text-secondary-d1 {
  color: #5f718b !important;
}

.text-180 {
  font-size: 1.8em !important;
}

.text-150 {
  font-size: 1.5em !important;
}

.text-danger-m3 {
  color: #e05858 !important;
}

.rotate-45 {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.position-l {
  left: 0;
}

.position-b,
.position-bc,
.position-bl,
.position-br,
.position-center,
.position-l,
.position-lc,
.position-r,
.position-rc,
.position-t,
.position-tc,
.position-tl,
.position-tr {
  position: absolute !important;
  display: block;
}

.mt-n475,
.my-n475 {
  margin-top: -2.5rem !important;
}

.ml-35,
.mx-35 {
  margin-left: 1.25rem !important;
}

.text-dark-l1 {
  color: #56585e !important;
}

.text-90 {
  font-size: 0.9em !important;
}

.text-left {
  text-align: left !important;
}

.mt-25,
.my-25 {
  margin-top: 0.75rem !important;
}

.text-110 {
  font-size: 1.1em !important;
}

.deleted-text {
  text-decoration: line-through;
}

.ratings {
  margin-right: 10px;
}

.ratings i {
  color: #cecece;
  font-size: 20px;
  margin-right: 5px;
}

.rating-color {
  color: #fbc634 !important;
}

/* Performers CSS */
.card-performers {
  width: 230px;

  box-shadow: 0px 0px 0px 0px #e9ecef;
  border-radius: 12px;
}

.circle-image img {
  border: 6px solid #fff;
  border-radius: 100%;
  padding: 0px;
  top: -28px;
  position: relative;
  width: 70px;
  height: 70px;
  border-radius: 100%;
  z-index: 1;
  background: #e7d184;
  cursor: pointer;
}

.performance-image {
  border: 6px solid #fff;
  border-radius: 100%;
  padding: 0px;
  top: -28px;
  position: relative;
  width: 70px;
  height: 70px;
  border-radius: 100%;
  z-index: 1;
  background: #e7d184;
  cursor: pointer;
}

.six-pointed-star {
  --star-color: #0038b8;
  --sqrt-3: 1.73205080757;
  position: relative;
  top: -50px;
  left: 126px;
  font-size: 0.5em;
  z-index: 1000;
  width: 0;
  height: 0;
  position: relative;
  border-left: 1em solid transparent;
  border-right: 1em solid transparent;
  border-bottom: calc(1em * var(--sqrt-3)) solid var(--star-color);
}

.six-pointed-star:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  left: -1em;
  top: calc(1em * var(--sqrt-3) / 3);
  border-left: 1em solid transparent;
  border-right: 1em solid transparent;
  border-top: calc(1em * var(--sqrt-3)) solid var(--star-color);
}
.dot {
  height: 18px;
  width: 18px;
  background-color: blue;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 3px solid #fff;
  top: -48px;
  left: 126px;
  z-index: 1000;
}

.name {
  margin-top: -41px;
  font-size: 18px;
}

.fw-500 {
  font-weight: 500 !important;
}

.start {
  color: green;
}

.stop {
  color: red;
}

.rate {
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
}

.rating {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
}

.rating > input {
  display: none;
}

.rating > label {
  position: relative;
  width: 1em;
  font-size: 30px;
  font-weight: 300;
  color: #ffd600;
  cursor: pointer;
}

.rating > label::before {
  content: "\2605";
  position: absolute;
  opacity: 0;
}

.rating > label:hover:before,
.rating > label:hover ~ label:before {
  opacity: 1 !important;
}

.rating > input:checked ~ label:before {
  opacity: 1;
}

.rating:hover > input:checked ~ label:before {
  opacity: 0.4;
}

.buttons-performers {
  top: 36px;
  position: relative;
}

.rating-submit {
  border-radius: 15px;
  color: #fff;
  height: 49px;
}

.rating-submit:hover {
  color: #fff;
}

.icon-image-up {
  width: 50px;
  height: 50px;
  background-color: #eee;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 39px;
}
/* Configuration Flow */
.p-lg {
  padding: 10px;
  padding-left: 30px !important;
}

.ActionCenterBlueBarPadding {
  padding: 0px 10px 0px 30px;
}

.lazur-bg {
  background-color: #4e84ff;
  color: #ffffff;
}

.widget-head-color-box {
  border-radius: 10px 10px 0px 0px;
  margin-top: 0px;
  min-height: 75px;
}

.m-b-md {
  margin-bottom: 20px;
}

.no-margins {
  margin: 0 !important;
  font-size: x-large !important;
}

.font-bold {
  font-weight: 600;
}

img.circle-border {
  border: 6px solid #ffffff;
  border-radius: 50%;
}

.widget-text-box {
  padding: 20px;
  border: 1px solid #e7eaec;
  background: #ffffff;
  border-radius: 10px;
}

.media-heading {
  margin-top: 0;
  margin-bottom: 5px;
}

.ws-success-disabled {
  cursor: auto !important;
}

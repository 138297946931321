a {
  text-decoration: none;
}
.login-page {
  width: 100%;
  height: 100vh;
  display: inline-block;
  display: flex;
  align-items: center;
}
.form-right i {
  font-size: 100px;
}
.link-route {
  text-decoration: none;
font-weight: 600;
}
.vertical-line{
border-left: 1px solid rgb(255, 255, 255);

}
.link-route:hover {
  color: rgb(255, 208, 0);
  text-decoration: none;
  font-weight: 600;
}
